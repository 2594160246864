import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "workspace-item-wrapper" }
const _hoisted_2 = { class: "item-details title project" }
const _hoisted_3 = { class: "item-details light project" }
const _hoisted_4 = { class: "item-details light bold project" }
const _hoisted_5 = {
  key: 0,
  class: "item-options"
}
const _hoisted_6 = { class: "item-option color-picker" }
const _hoisted_7 = { class: "code-embed-2 w-embed" }
const _hoisted_8 = {
  key: 1,
  class: "item-options"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "item-icon active",
      style: _normalizeStyle(`color: ${_ctx.projectColor}`)
    }, "code", 4),
    _createElementVNode("div", {
      class: "item-detail-wrapper",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goToProject(_ctx.project.name)))
    }, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.project.name), 1),
      _createElementVNode("div", _hoisted_3, "By: " + _toDisplayString(_ctx.project.author), 1),
      _createElementVNode("div", _hoisted_4, " Updated: " + _toDisplayString(new Date(_ctx.project.updatedDate).toLocaleString()), 1)
    ]),
    _createElementVNode("div", {
      class: "item-icon",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showSettings = true))
    }, "settings"),
    (_ctx.showSettings)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", {
            class: "item-option red",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.deleteQuestion = true))
          }, "Delete"),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _withDirectives(_createElementVNode("input", {
                type: "color",
                style: {"border":"0px !important"},
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.projectColor) = $event))
              }, null, 512), [
                [_vModelText, _ctx.projectColor]
              ])
            ])
          ]),
          _createElementVNode("div", {
            class: "item-option-close-btn",
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showSettings = false))
          }, " close ")
        ]))
      : _createCommentVNode("", true),
    (_ctx.showSettings && _ctx.deleteQuestion)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createElementVNode("div", {
            class: "item-option",
            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.deleteQuestion = false))
          }, "Cancel"),
          _createElementVNode("div", {
            class: "item-option red",
            onClick: _cache[6] || (_cache[6] = ($event: any) => {
        _ctx.deleteProject(_ctx.project);
      _ctx.deleteQuestion = false;
      _ctx.showSettings = false;
      })
          }, " Delete ")
        ]))
      : _createCommentVNode("", true)
  ]))
}