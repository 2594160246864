<template>
  <div class="tags-div">
    <div class="script-tags-box">
      <div class="script-tags-box-title"><strong>Publish</strong></div>
      <div class="script-tags-toggle">
        <div class="script-tag-toggle-btn" :class="{ 'selected-dev': selectedEnv == 'dev' }"
          @click="selectedEnv = 'dev'">
          Development Scripts</div>
        <div class="script-tag-toggle-btn" :class="{ 'selected': selectedEnv == 'prod' }" @click="selectedEnv = 'prod'">
          Production Scripts</div>
      </div>
      <div class="scripts-form-block dev w-form" :class="{ open: selectedEnv == 'dev' }">
        <div class="script-tag-actions-wrapper">
          <div class="btn move-to-dev" @click="publishToDev">
            <div class="normal-icon">done</div>
            <div class="btn-text">Publish to Development</div>
          </div>
        </div>
        <form class="scripts-tags-form">
          <!--Dev HTML Script-->
          <ScriptTagOutput :env="'dev'" :lang="'html'" />
          <!--Dev CSS Script-->
          <ScriptTagOutput :env="'dev'" :lang="'css'" />
          <!--Dev JavaScript Script-->
          <ScriptTagOutput :env="'dev'" :lang="'js'" />
        </form>
      </div>
      <div class="scripts-form-block prod w-form" :class="{ open: selectedEnv == 'prod' }">
        <div class="script-tag-actions-wrapper">
          <div class="btn move-to-prod" @click="moveToProd">
            <div class="normal-icon">done</div>
            <div class="btn-text">Move to Production</div>
          </div>
        </div>
        <form class="scripts-tags-form">
          <!--Prod HTML Script-->
          <ScriptTagOutput :env="'prod'" :lang="'html'" />
          <!--Prod CSS Script-->
          <ScriptTagOutput :env="'prod'" :lang="'css'" />
          <!--Prod Javascript Script-->
          <ScriptTagOutput :env="'prod'" :lang="'js'" />
        </form>
      </div>
      <div class="script-tags-box-btn-wrapper">
        <div class="notification-btn" @click="setShowScriptTags(false)">Hide</div>
      </div>
      <div class="code-upload-loading-wrapper" v-if="showLoading">
        <div class="code-upload-loading"></div>
        <div class="text-center">Publishing</div>
      </div>
    </div>

  </div>

  <Notification v-if="showNotification" :options="notificationOptions" />


</template>

<script lang="ts">
import router from "@/router";
import { defineComponent, version } from "vue";
import { mapActions, mapMutations, mapState } from "vuex";
import ScriptTagOutput from "./ScriptTagOutput.vue";
import { storage } from "@/firebase-app";
import {
  getDownloadURL, ref as storageRef,
  StorageReference,
  uploadString
} from "firebase/storage";
import Notification from "@/components/Notification.vue";


export default defineComponent({
  name: "ChirpCard",
  components: {
    ScriptTagOutput,
    Notification,
  },
  data() {
    return {
      selectedEnv: 'dev',
      notificationOptions: null as any,
      showNotification: false,
      showLoading: false,
      cdnScripts: "",
    };
  },
  mounted() {

  },
  computed: {
    ...mapState("modLogin", ["user"]),
    ...mapState("modProjects", ["selectedProject"]),
  },

  methods: {
    ...mapActions("modChirp", ["deleteChirp"]),
    ...mapActions("modVersioning", ["markVersionProd", "markVersion"]),
    ...mapMutations("modProjects", ["setShowScriptTags"]),
    ...mapMutations(["setCreateProdScriptFlag"]),
    async publishToDev() {
      this.showLoading = true
      await this.uploadCode('dev', 'index.html')
      await this.uploadCode('dev', 'style.css')
      await this.uploadCode('dev', 'script.js')
      this.notificationOptions = {
        question: false, // question | confirm
        yesText: 'OK',
        title: `Published to Develpment!`,
        text: 'Your code is now live',
        yesFunction: async () => {
          this.setShowScriptTags(false)
          setTimeout(() => {
            this.setShowScriptTags(true)
          }, 100);
          this.showNotification = false;
          this.notificationOptions = null as any;
        },
        isError: false,
      };

      this.showNotification = true;
      this.showLoading = false
    },
    async moveToProd() {
      this.notificationOptions = {
        question: true, // question | confirm
        yesText: 'Move To Production',
        noText: 'Cancel',
        title: `Moving To Production`,
        text: 'This will overwrite the production code and mark this version as the production version',
        yesFunction: async () => {
          this.showLoading = true
          this.showNotification = false;
          await this.uploadCode('prod', 'index.html')
          await this.uploadCode('prod', 'style.css')
          await this.uploadCode('prod', 'script.js')
          this.notificationOptions = {
            question: false, // question | confirm
            yesText: 'OK',
            title: `Published to Production!`,
            text: 'Your code is now live',
            yesFunction: async () => {
              this.showNotification = false;
              this.notificationOptions = null as any;
            },
            isError: false,
          };
          this.showNotification = true;
          this.showLoading = false
        },
        noFunction: () => {
          this.showNotification = false;
          this.notificationOptions = null as any;

        },
        isError: false,
      };
      this.showNotification = true;
    },

    async uploadCode(env: string, fileName: string) {
      this.showLoading = true
      this.cdnScripts = ""
      this.selectedProject.cdnList.forEach((cdn: string) => {
        this.cdnScripts += `<script src="${cdn}"><\/script>`
      });
      const codeStorageRef = storageRef(
        storage,
        `scripts/${this.user.uid}/${this.selectedProject.slug}/${env}/${fileName}`
      );
      let code = "";
      switch (fileName) {
        case 'index.html':
          code = `<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="X-UA-Compatible" content="ie=edge">
    <title>${this.selectedProject.id}<\/title>
    <style>${this.selectedProject.cssCode}<\/style>
  <\/head>
  <body>
    ${this.selectedProject.htmlCode}
    ${this.cdnScripts}
    <script type="text/javascript">${this.selectedProject.jsCode}<\/script>
  <\/body>
<\/html>`
          break
        case 'style.css': code = this.selectedProject.cssCode; break
        case 'script.js': code = this.selectedProject.jsCode; break
      }
      const metadata = {
        contentType: 'text/html', // Set the correct content type
      };
      if (fileName !== 'style.css') {
        await uploadString(codeStorageRef, code, 'raw', metadata).then((snapshot) => {
          console.log("Uploaded a raw string!", snapshot);
        });
      } else {
        await uploadString(codeStorageRef, code, 'raw', {
          contentType: 'text/css'
        }).then((snapshot) => {
          console.log("Uploaded css file", snapshot);
        });

      }
      this.showLoading = false
    },


  },
});
</script>
