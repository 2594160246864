<template>
  <div class="body">
    <div class="workspace-wrapper">
      <div class="side-menu">
        <div class="side-menu-item" :class="{ selected: projectMenuTab == 'AI' }" @click="setProjectMenuTab('AI')">
          <div class="side-menu-item-icon">neurology</div>
        </div>
        <div class="side-menu-item" :class="{ selected: projectMenuTab == 'doc' }" @click="setProjectMenuTab('doc')">
          <div class="side-menu-item-icon">description</div>
        </div>
        <div class="side-menu-item" :class="{ selected: projectMenuTab == 'versioning' }" @click="setProjectMenuTab('versioning')">
          <div class="side-menu-item-icon">folder_data</div>
        </div>
        <div class="side-menu-item" :class="{ selected: projectMenuTab == 'settings' }" @click="setProjectMenuTab('settings')">
          <div class="side-menu-item-icon">settings</div>
        </div>
      </div>
      <SplitterGroup id="splitter-group-3" direction="horizontal">
        <SplitterPanel id="splitter-group-3-panel-1" v-show="projectMenuTab" :min-size="20" :default-size="25" class="SplitterPanel">
          <div class="left-panel">
            <AiChatOpenAi v-show="aiProvider == 'OpenAI' && projectMenuTab == 'AI'" v-if="selectedProject"/>
            <AiChatGemini v-show="aiProvider == 'Google Gemini' && projectMenuTab == 'AI'" v-if="selectedProject"/>
            <AiChat v-show="aiProvider == 'Local Ollama' && projectMenuTab == 'AI'" v-if="selectedProject"/>
            
            <VersioningComp v-if="projectMenuTab == 'versioning'" />
            <div class="left-panel-list project" v-show="projectMenuTab == 'doc'">
              <div class="doc-staus-div">
                <div class="doc-status-wrapper">
                  <div class="btn project-control doc-save" @click="setDocumentation">Save</div>
                  <div class="doc-status red" v-if="initProjectDoc != newProjectDoc">Not Saved</div>
                </div>


              </div>

              <quillComp :content="initProjectDoc" @contentUpdated="(content) => {
                newProjectDoc = content
              }" />


            </div>

            <ProjectSettings v-if="projectMenuTab == 'settings'" />
          </div>
        </SplitterPanel>
        <SplitterResizeHandle id="splitter-group-3-resize-handle-1" class="SplitterResizeHandle"/>
        <SplitterPanel id="splitter-group-3-panel-2" :min-size="5" class="SplitterPanel">
          <div class="right-panel project">

            <div class="project-code-editor">
              <div class="code-editors-container" v-if="selectedProject">

                <SplitterGroup id="splitter-group-1" direction="vertical">
                  <SplitterPanel id="splitter-group-1-panel-1" :min-size="5" class="SplitterPanel">
                    <SplitterGroup id="splitter-group-2" direction="horizontal">
                      <SplitterPanel id="splitter-group-2-panel-1" :min-size="5" class="SplitterPanel"
                        v-show="htmlEditorView">

                        <CodeEditor :language="'html'" @codeUpdated="(code) => {
                          setHtmlCode(code);
                        }" />

                      </SplitterPanel>
                      <SplitterResizeHandle id="splitter-group-2-resize-handle-1" class="SplitterResizeHandle"
                        v-show="htmlEditorView" />
                      <SplitterPanel id="splitter-group-2-panel-2" :min-size="5" class="SplitterPanel"
                        v-show="cssEditorView">

                        <CodeEditor :language="'css'" @codeUpdated="(code) => {
                          setCssCode(code)
                        }" />

                      </SplitterPanel>
                      <SplitterResizeHandle id="splitter-group-2-resize-handle-2" class="SplitterResizeHandle"
                        v-show="cssEditorView" />
                      <SplitterPanel id="splitter-group-2-panel-3" :min-size="5" class="SplitterPanel"
                        v-show="jsEditorView">

                        <CodeEditor :language="'javascript'" @codeUpdated="(code) => {
                          setJsCode(code)
                        }" />

                      </SplitterPanel>
                    </SplitterGroup>

                  </SplitterPanel>
                  <SplitterResizeHandle id="splitter-group-1-resize-handle-1" class="SplitterResizeHandle" />
                  <SplitterPanel id="splitter-group-1-panel-2" :min-size="5" class="SplitterPanel">

                    <PreviewComp v-if="selectedProject" :key="previewKey"/>

                  </SplitterPanel>
                </SplitterGroup>



              </div>
              <div class="code-lock" v-if="projectMenuTab == 'versioning'">
                <div class="version-preview-banner">
                  <div class="version-preview-text" v-if="selectedVersion">
                    You are previewing version {{ selectedVersion.number }}
                  </div>
                  <div class="version-preview-text" v-else>
                    You are previewing your current work.
                  </div>
                  <div class="version-preview-text btn" @click="setProjectMenuTab(lastTab)">
                    Exit Preview
                  </div>
                </div>
              </div>
            </div>
            <div class="project-footer" v-if="projectMenuTab != 'versioning'">
              <div class="save-action-wrapper">
                <div class="btn save" :class="{ saving: saving }" @click="saveCode">
                  Save
                </div>
                <img
                  src="https://cdn.prod.website-files.com/66b2a9b55aec6503eeabce5c/66e8457dc8ac9ca8ab5ec740_d158b93b-bded-4b54-ad27-8d22879a5fc2.svg"
                  loading="lazy" alt="" class="save-animation" :class="{ saving: saving }" />
                <div class="project-save-count" :class="{ saving: saving }">
                  Saving
                </div>
              </div>
            </div>
          </div>
        </SplitterPanel>
      </SplitterGroup>
    </div>
    <ScriptTagsComp v-if="showScriptTags" />
    <NewChirpDialog v-if="newChirpDialog" />
    <Notification v-if="showNotification" :options="notificationOptions" />
  </div>
</template>

<script lang="ts">
import { defineComponent, shallowRef } from "vue";
import { mapActions, mapMutations, mapState } from "vuex";

import { db } from "@/firebase-app";
import { useRoute } from "vue-router";
import NewChirpDialog from "@/components/NewChirpDialog.vue";
import ChirpItem from "@/components/ChirpItem.vue";
import * as monaco from "monaco-editor";
import CodeEditor from "@/components/CodeEditor.vue";
import Notification from "@/components/Notification.vue";
import AiChat from "@/components/AiChat.vue";
import quillComp from "@/components/quillComp.vue";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { Codemirror } from "vue-codemirror";
import { javascript } from "@codemirror/lang-javascript";
import { oneDark } from "@codemirror/theme-one-dark";
import PreviewComp from "@/components/PreviewComp.vue";
import ProjectSettings from "@/components/ProjectSettings.vue";
import VersioningComp from "@/components/VersioningComp.vue";
import ScriptTagsComp from "@/components/ScriptTags/ScriptTagsComp.vue";
import { SplitterGroup, SplitterPanel, SplitterResizeHandle } from 'radix-vue'
import AiChatGemini from "@/components/AiChatGemini.vue";
import AiChatOpenAi from "@/components/AiChatOpenAi.vue";

export default defineComponent({
  name: "ProjectsView",
  components: {
    NewChirpDialog,
    ChirpItem,
    Codemirror,
    Notification,
    AiChat,
    quillComp,
    PreviewComp,
    CodeEditor,
    ProjectSettings,
    VersioningComp,
    ScriptTagsComp,
    SplitterGroup,
    SplitterPanel,
    SplitterResizeHandle,
    AiChatGemini,
    AiChatOpenAi,
  },
  data() {
    return {
      initProjectDoc: "",
      newProjectDoc: "",
      lastTab: "",

      searchTerm: null,
      chirpsTab: "myChirps",
      notificationOptions: null as any,
      showNotification: false,
      projectId: "" as string | string[],
      loadingProject: false,

      saving: false,
      editorRef: shallowRef(null),
      MONACO_EDITOR_OPTIONS: {
        automaticLayout: true,
        formatOnType: true,
        formatOnPaste: true,
        folding: true,
      },
      chirpCodeExtentions: [javascript(), oneDark],
      deletingChirp: false,
    };
  },
  async mounted() {
    this.loadingProject = true;
    // get the project details
    const route = useRoute();
    this.projectId = route.params.id;

    if (this.projectId) {
      // console.log("project id: ", this.projectId);
      this.getProject(this.projectId)
      this.setSelectedProjectId(this.projectId);
      this.getVersions(this.projectId)



      await this.getDocumentation();
      document.addEventListener("keydown", this.handleSave);
      this.loadingProject = false;
      if (this.user) {
        // this.fetchUserChirps(this.user.uid);
        this.searchForChirps({
          isPublic: false,
          searchTerm: this.searchTerm,
        });
      }

    } else {
      console.log("no project id found");
    }
  },
  beforeUnmount() {
    document.removeEventListener("keydown", this.handleSave);

  },
  computed: {
    ...mapState("modLogin", ["user"]),
    ...mapState("modProjects", [
      "selectedProject",
      "showScriptTags",
    ]),
    ...mapState("modVersioning", ["selectedVersion", "jsCode", "htmlCode", "cssCode"]),
    ...mapState("modChirp", ["newChirpDialog", "chirps", "selectedChirp"]),
    ...mapState(["showPreview", "htmlEditorView", "cssEditorView", "jsEditorView", "projectMenuTab", "previewKey", "aiProvider"])
  },
  watch: {
    projectMenuTab(newVal, oldVal) {
      this.lastTab = oldVal;
      if (newVal == "versioning") {

      } else if (oldVal == "versioning") {
        this.setSelectedVersion(null)
      } else if (oldVal == "doc" && this.initProjectDoc != this.newProjectDoc) {
        this.setDocumentation();
        this.notificationOptions = null as any;
        this.setAppIsLoading(false);
      }
    },
  },
  methods: {
    ...mapActions("modChirp", ["fetchUserChirps", "deleteChirp", "searchForChirps"]),
    ...mapActions("modVersioning", ["getVersions", "createNewVersion"]),
    ...mapActions("modProjects", ["getProject"]),
    ...mapMutations("modChirp", ["setNewChirpDialog", "setSelectedChirp"]),
    ...mapMutations("modProjects", ["setSelectedProjectId"]),
    ...mapMutations("modVersioning", ["setJsCode", "setHtmlCode", "setCssCode", "setSelectedVersion"]),
    ...mapMutations(["setAppIsLoading", "refreshPreview", "setProjectMenuTab", "updatePreview"]),

    async getDocumentation() {
      const projectRef = doc(db, `users/${this.user.uid}/projects`, this.projectId as string)
      const projectDoc = (await getDoc(projectRef)).data();
      if (projectDoc && projectDoc.document) {
        this.initProjectDoc = projectDoc.document;
        this.newProjectDoc = projectDoc.document;
        // console.log(projectDoc.document)
      } else {
        this.initProjectDoc = `<P>By: ${this.user.displayName}</p>`
      }
    },
    async setDocumentation() {
      const projectRef = doc(db, `users/${this.user.uid}/projects`, this.projectId as string)
      await setDoc(projectRef, {
        ["document"]: this.newProjectDoc
      }, { merge: true })
      await this.getDocumentation();
    },

    handleEditChirp() {
      if (true) {
        const warning = {
          question: true, // question | confirm
          yesText: "Save & Continue",
          noText: "Discard",
          title: "Save your changes",
          text: "Do you want to save your code before editing this chirp?",
          yesFunction: () => {
            this.$router.push(`/chirp/${this.selectedChirp.id}`);
            this.setSelectedChirp(null as any);
          },
          noFunction: () => {
            this.showNotification = false;
            this.notificationOptions = null as any;
          },
          isError: Boolean,
        };
        this.notificationOptions = warning;
        this.showNotification = true;
      } else {
        this.$router.push(`/chirp/${this.selectedChirp.id}`);
        this.setSelectedChirp(null as any);
      }
    },
    insertChirpAtEnd() {
      if (!this.selectedChirp) {
        return;
      } else {
        const newValue = this.jsCode + "\n" + this.selectedChirp.code;
        if (this.editorRef) (this.editorRef as any).setValue(newValue);
      }
    },
    insertChirpAtcursor() {
      //const range = new monaco.Range(position.lineNumber, position.column, position.lineNumber, position.column);
      console.log((this.editorRef as any).getPosition());
      const position = (this.editorRef as any).getPosition();
      const range = new monaco.Range(
        position.lineNumber,
        position.column,
        position.lineNumber,
        position.column
      );
      (this.editorRef as any).executeEdits("", [
        {
          range: range,
          text: this.selectedChirp.code,
          forceMoveMarkers: true,
        },
      ]);
    },

    async saveCode() {
      if(this.selectedVersion){return}
      const projectDoc = doc(db, `users/${this.user.uid}/projects/${this.selectedProject.id}`);
      await setDoc(projectDoc, {
        jsCode: this.jsCode,
        htmlCode: this.htmlCode,
        cssCode: this.cssCode,
      }, { merge: true })
    },

    async handleSave(e: any) {
      if (e.ctrlKey && e.key === "s") {
        e.preventDefault();
        // console.log("CTRL + S");
        await this.saveCode();
        // this.refreshPreview();
      }
    },

  },
});
</script>
<style lang="css" scoped>
/* reset */
button {
  all: unset;
}

.SplitterPanel {
  align-items: center;
  justify-content: center;
}

.SplitterResizeHandle[data-orientation="horizontal"] {
  width: 10px;
  background-color: #2A3034;
}

.SplitterResizeHandle[data-orientation="vertical"] {
  height: 10px;
  background-color: #1c1c1c;
}
</style>